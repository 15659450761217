import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import moment from "moment";
import reportService from "./reports-service";
import exportLimit from "../../common/export-limit.vue";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      sDate: "",
      eDate: "",
      showStartDate: true,
      showEndDate: true,
      startDate: false,
      endDate: false,
      selectedProject: "",
      projectList: [],
      totalRecords: 0,
      exportDisable: true,
      receiptData: [],
      disableAll: true,
      disableSpecific: true,
      selectedBusiness: "",
      businessData: [],
      selectedWarehouse: "",
      warehouseData: [],
      selectedClass: "",
      classData: [],
      selectedOEM: "",
      OEMData: [],
      selectedSOType: "",
      soTypeData: [],
      dateSelected: false,
      runDisabled: true,
      partClassSearchValid: false,
      page: 1,
      totalPages: 0,
      excelName: "",
      showPartNum: false,
      hideSpecific: false,
      hideAll: false,
      partSearchKey: "",
      showPartNumDetails: false,
      partResultList: [],
      showPartDetails: false,
      showBusiness: false,
      showWarehouse: false,
      showClass: false,
      showOEM: false,
      showSOType: false,
      export50kData: false,
      exportDialog: false,
      showPartType: false,
      showData: false,
      debouncedGetPartList: [],
      headersPartSearchTbl: [
        { text: "Part No", align: "start", value: "PartNo", class: "primary customwhite--text" },
        { text: "Project", value: "Project", class: "primary customwhite--text" },
        { text: "Description", value: "Description", class: "primary customwhite--text" },
        { text: "Model", value: "Model", class: "primary customwhite--text" },
        { text: "Part Type", value: "Part Type", class: "primary customwhite--text" },
        { text: "OEM Model", value: "OEM Model", class: "primary customwhite--text" },
        { text: "OEM Part", value: "OEM Part", class: "primary customwhite--text" },
      ],
      headerReceipt: [
        { text: "Business", value: "Business", class: "primary customwhite--text" },
        { text: "SO#", value: "So_No", class: "primary customwhite--text" },
        { text: "Line", value: "Line", class: "primary customwhite--text" },
        { text: "RMA", value: "RMA", class: "primary customwhite--text" },
        { text: "Item", value: "Item", class: "primary customwhite--text" },
        { text: "OEM Part", value: "OEM_Part", class: "primary customwhite--text" },
        { text: "Description", value: "Description", class: "primary customwhite--text" },
        { text: "Quantity", value: "Qty", class: "primary customwhite--text" },
        { text: "Warr", value: "Warr", class: "primary customwhite--text" },
        { text: "Serial No", value: "Serial_No", class: "primary customwhite--text" },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        { text: "Date Received", value: "Date_Rcvd", class: "primary customwhite--text" },
        { text: "Comments", value: "Comments", class: "primary customwhite--text" },
      ],
      json_fields: {
        Project: "Project",
        Business: "Business",
        "SO#": "SO#",
        Line: "Line",
        "Customer ID": "Cust_ID",
        "Customer Name": "Customer_Name",
        "Address 1": "Addr1",
        "Address 2": "Addr2",
        City: "City",
        State: "State",
        Zip: "Zip Code",
        Country: "Country",
        ATTN: "ATTN",
        "Order Number": "Order_No",
        "Customer PO": "Cust_Po",
        RMA: "RMA",
        Item: "Item",
        "OEM Part": "OEM_Part",
        Description: "Description",
        Model: "Model",
        Class: "Class",
        OEM: "OEM",
        "Standard Cost": "Std_Cost",
        Quantity: "Qty",
        Warranty: "Warranty",
        BCN: "BCN",
        "Serial No": "Serial_No",
        ESN: "ESN",
        "RLP ID": "License_Plate",
        "Date Received": "Date_Rcvd",
        "Order Type": "Order_Type",
        "RMA Type": "RMA_Type",
        "Reason Code": "Reason_Code",
        Condition: "Condition",
        DNC: "DNC",
        CRA: "CRA",
        Carrier: "Carrier",
        "Waybill (Inbound)": "Waybill (inbound)",
        "Packslip (Inbound)": "Packslip (inbound)",
        "Vendor Reference No": "Vendor_Ref_No",
        Location: "Location",
        Warehouse: "Warehouse",
        "Observed Problem": "Observed Problem",
        Comments: "Comments",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.debouncedGetPartList = Utility.debounce(this.getPartList, 500);
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.eDate ? moment(this.eDate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.selectedProject = "";
      this.projectList = [];
      this.totalRecords = 0;
      this.exportDisable = true;
      this.receiptData = [];
      this.disableAll = true;
      this.disableSpecific = true;
      this.selectedBusiness = "";
      this.businessData = [];
      this.selectedWarehouse = "";
      this.warehouseData = [];
      this.selectedClass = "";
      this.classData = [];
      this.selectedOEM = "";
      this.OEMData = [];
      this.selectedSOType = "";
      this.soTypeData = [];
      this.dateSelected = false;
      this.runDisabled = true;
      this.partClassSearchValid = false;
      this.page = 1;
      this.totalPages = 0;
      this.excelName = "";
      this.showPartNum = false;
      this.hideSpecific = false;
      this.hideAll = false;
      this.partSearchKey = "";
      this.showPartNumDetails = false;
      this.partResultList = [];
      this.showPartDetails = false;
      this.showBusiness = false;
      this.showWarehouse = false;
      this.showClass = false;
      this.showOEM = false;
      this.showSOType = false;
      this.export50kData = false;
      this.exportDialog = false;
      this.showPartType = false;
      this.showData = false;
    },
    // set end Date
    setEndDate() {
      let minEndDate = new Date(this.sDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    //On click continue
    async onContinue() {
      !this.sDate ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : "";
      let obj = {
        pass: 0,
        user_id: parseInt(this.userId),
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        proj_id: 0,
        part_num: "",
        bu_id: 0,
        class_id: 0,
        ware_id: 0,
        oem_id: 0,
        sotype_id: 0,
        include_comments: true,
        RecordCount: 0,
        PageNumber: 0,
        export: 0,
        guid: "",
      };
      let showMessage = false;
      let projData = await reportService.salesOrderReceiptReport("post", obj, showMessage);
      if (projData.message !== "NA") {
        this.projectList = projData;
        this.dateSelected = true;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Data Available change the Start Date",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.dateSelected = false;
      }
    },
    //Project Selected
    async onChangeProject() {
      let obj = {
        pass: 1,
        user_id: parseInt(this.userId),
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        proj_id: parseInt(this.selectedProject),
        part_num: "",
        bu_id: 0,
        class_id: 0,
        ware_id: 0,
        oem_id: 0,
        sotype_id: 0,
        include_comments: true,
        RecordCount: 0,
        PageNumber: 0,
        export: 0,
        guid: "",
      };
      let showMessage = false;
      let busData = await reportService.salesOrderReceiptReport("post", obj, showMessage);
      if (busData.message !== "NA") {
        this.businessData = busData;
        this.disableAll = false;
        this.disableSpecific = false;
        this.runDisabled = true;
        this.showPartNum = true;
        this.hideSpecific = false;
        this.hideAll = false;
        this.partSearchKey = "";
        this.showPartNumDetails = false;
        this.partResultList = [];
        this.showPartDetails = false;
        this.showBusiness = false;
        this.showWarehouse = false;
        this.showClass = false;
        this.showOEM = false;
        this.showSOType = false;
        this.receiptData = [];
        this.totalRecords = 0;
        this.totalPages = 0;
        this.exportDisable = true;
        this.showData = false;
        this.clearPartShip();
      }
    },
    //All Part Clicked
    allPartNumber() {
      this.showBusiness = true;
      this.showPartType = false;
      this.showParentClass = false;
      this.showPartClass = false;
      this.showWarehouse = false;
      this.showInventoryLoc = false;
      this.runDisabled = true;
      this.hideSpecific = true;
      this.showData = false;
      this.clearPartShip();
    },
    async searchPartNum() {
      this.partSearchKey = this.partSearchKey?.toUpperCase();
      if (this.partSearchKey?.length > 2) {
        this.debouncedGetPartList();
      }
    },
    async getPartList() {
      this.showPartNumDetails = true;
      let SearchObj = {
        UserId: this.userId,
        SearchStr: this.partSearchKey,
        proj_key: this.selectedProject,
      };
      let partResultData = await reportService.showPartNumDetailsData("post", SearchObj, false);
      this.partResultList = partResultData?.Resultset;
    },
    clearPartShip() {
      this.showPartDetails = false;
      this.partSearchKey = "";
      this.partResultList = [];
      this.partNum = "";
      this.partDesc = "";
    },
    showPartDesc(item) {
      this.showPartDetails = true;
      this.partNum = item.PartNo;
      this.partDesc = item.Description;
      this.partSearchKey = item.PartNo;
      this.showPartNumDetails = false;
      this.partDetailsShow = false;
      this.showBusiness = true;
      this.showPartType = true;
      this.showParentClass = false;
      this.showPartClass = false;
      this.showWarehouse = false;
      this.showInventoryLoc = false;
      this.runDisabled = true;
      this.showData = false;
      this.hideAll = true;
    },
    //Business selected
    async businessSelected() {
      let obj = {
        pass: 2,
        user_id: parseInt(this.userId),
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        proj_id: parseInt(this.selectedProject),
        part_num: "",
        bu_id: parseInt(this.selectedBusiness),
        class_id: 0,
        ware_id: 0,
        oem_id: 0,
        sotype_id: 0,
        include_comments: true,
        RecordCount: 0,
        PageNumber: 0,
        export: 0,
        guid: "",
      };
      let showMessage = false;
      let otherData = await reportService.salesOrderReceiptReport("post", obj, showMessage);
      if (otherData.message !== "NA") {
        this.warehouseData = otherData;
        this.classData = otherData;
        this.OEMData = otherData;
        this.soTypeData = otherData;
        this.selectedWarehouse = "";
        this.selectedClass = "";
        this.selectedOEM = "";
        this.selectedSOType = "";
        this.runDisabled = true;
        this.partSearchKey = "";
        this.showPartNumDetails = false;
        this.partResultList = [];
        this.showPartDetails = true;
        this.showWarehouse = true;
        this.showClass = false;
        this.showOEM = false;
        this.showSOType = false;
        this.showData = false;
      }
    },
    //Warehouse selected
    warehouseSelected() {
      this.selectedClass = "";
      this.selectedOEM = "";
      this.selectedSOType = "";
      this.showClass = true;
      this.showOEM = false;
      this.showSOType = false;
      this.runDisabled = true;
      this.showData = false;
    },
    //Class selected
    classSelected() {
      this.selectedOEM = "";
      this.selectedSOType = "";
      this.runDisabled = true;
      this.showOEM = true;
      this.showSOType = false;
      this.showData = false;
    },
    //OEM Selected
    OEMSelected() {
      this.selectedSOType = "";
      this.runDisabled = true;
      this.showSOType = true;
      this.showData = false;
    },
    //Run Report
    async runReport() {
      let obj = {
        pass: 3,
        user_id: parseInt(this.userId),
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        proj_id: parseInt(this.selectedProject),
        part_num: this.partNum,
        bu_id: parseInt(this.selectedBusiness == null || this.selectedBusiness == "" ? 0 : this.selectedBusiness),
        class_id: parseInt(this.selectedClass == null || this.selectedClass == "" ? 0 : this.selectedClass),
        ware_id: parseInt(this.selectedWarehouse == null || this.selectedWarehouse == "" ? 0 : this.selectedWarehouse),
        oem_id: parseInt(this.selectedOEM == null || this.selectedOEM == "" ? 0 : this.selectedOEM),
        sotype_id: parseInt(this.selectedSOType == null || this.selectedSOType == "" ? 0 : this.selectedSOType),
        include_comments: true,
        RecordCount: 0,
        PageNumber: 1,
        export: 0,
        guid: "",
      };
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .post("/rt/so_receipt_report_new", obj)
        .then((response) => {
          if (response.status == 200) {
            let responseData = response.data.body;
            if (responseData?.length !== 0) {
              const newArr = responseData.map((obj) => {
                return {
                  ...obj,
                  Date_Rcvd: Utility.convertESTToLocal(obj.Date_Rcvd),
                };
              });
              this.receiptData = newArr;
              this.totalRecords = responseData[0]?.TotalRecords;
              this.totalPages = responseData[0]?.TotalPages;
              this.exportDisable = false;
              this.showData = true;
            } else {
              this.receiptData = [];
              this.totalRecords = 0;
              this.totalPages = 0;
              this.exportDisable = true;
              this.showData = false;
              let Alert = {
                type: "error",
                isShow: true,
                message: "No records based on the selected criteria",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Run Report
    async onClickSearch(page) {
      let obj = {
        pass: 3,
        user_id: parseInt(this.userId),
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        proj_id: parseInt(this.selectedProject),
        part_num: this.partNum,
        bu_id: parseInt(this.selectedBusiness),
        class_id: parseInt(this.selectedClass),
        ware_id: parseInt(this.selectedWarehouse),
        oem_id: parseInt(this.selectedOEM),
        sotype_id: parseInt(this.selectedSOType),
        include_comments: true,
        RecordCount: 0,
        PageNumber: page,
        export: 0,
        guid: "",
      };
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .post("/rt/so_receipt_report_new", obj)
        .then((response) => {
          if (response.status == 200) {
            let responseData = response.data.body;
            if (responseData?.length !== 0) {
              const newArr = responseData.map((obj) => {
                return {
                  ...obj,
                  Date_Rcvd: Utility.convertESTToLocal(obj.Date_Rcvd),
                };
              });
              this.receiptData = newArr;
              this.totalRecords = responseData[0]?.TotalRecords;
              this.totalPages = responseData[0]?.TotalPages;
              this.exportDisable = false;
              this.showData = true;
            } else {
              this.receiptData = [];
              this.totalRecords = 0;
              this.totalPages = 0;
              this.exportDisable = true;
              this.showData = false;
              let Alert = {
                type: "error",
                isShow: true,
                message: "No records based on the selected criteria",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Export Data using component
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    async exportToExcel() {
      if (this.totalRecords <= 15000) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sales Order Receipts");
        const borderStyle = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
        const colors = {
          grey: { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } },
        };
        //Add Header Rows
        worksheet.addRow([
          "Project",
          "Business",
          "SO#",
          "Line",
          "Customer ID",
          "Customer Name",
          "Address 1",
          "Address 2",
          "City",
          "State",
          "Zip",
          "Country",
          "ATTN",
          "Order Number",
          "Customer PO",
          "RMA",
          "Item",
          "OEM Part",
          "Description",
          "Model",
          "Class",
          "OEM",
          "Standard Cost",
          "Quantity",
          "Warranty",
          "BCN",
          "Serial No",
          "ESN",
          "RLP ID",
          "Date Received",
          "Order Type",
          "RMA Type",
          "Reason Code",
          "Condition",
          "DNC",
          "CRA",
          "Carrier",
          "Waybill (Inbound)",
          "Packslip (Inbound)",
          "Vendor Reference No",
          "Location",
          "Warehouse",
          "Observed Problem",
          "Comments",
        ]);
        // Style configuration
        const headerStyle = {
          font: { bold: true },
          alignment: { horizontal: "center", vertical: "middle" },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          fill: { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } },
        };
        // Apply styles to the header rows
        worksheet.getRow(1).eachCell((cell) => {
          cell.style = { ...headerStyle, fill: colors.grey };
          cell.border = borderStyle;
        });
        let obj = {
          pass: 3,
          user_id: parseInt(this.userId),
          lcsdate: Utility.convertLocalToUTC(this.sDate),
          lcedate: Utility.convertLocalToUTC(this.eDate),
          proj_id: parseInt(this.selectedProject),
          part_num: this.partNum,
          bu_id: parseInt(this.selectedBusiness),
          class_id: parseInt(this.selectedClass),
          ware_id: parseInt(this.selectedWarehouse),
          oem_id: parseInt(this.selectedOEM),
          sotype_id: parseInt(this.selectedSOType),
          include_comments: true,
          RecordCount: 0,
          PageNumber: 1,
          export: 1,
          guid: "",
        };
        const response = await this.axios.post("/rt/so_receipt_report_new_export", obj);
        let responseData = response.data.body;
        const newArr = responseData.map((obj) => {
          return {
            ...obj,
            Date_Rcvd: Utility.convertESTToLocal(obj.Date_Rcvd),
          };
        });
        this.export50kData = false;
        //Add your data rows
        newArr.forEach((item) => {
          const row = worksheet.addRow([
            item.Project,
            item.Business,
            item.So_No,
            item.Line_No,
            item.Cust_ID,
            item.Customer_Name,
            item.Addr1,
            item.Addr2,
            item.City,
            item.State,
            item.ZipCode,
            item.Country,
            item.ATTN,
            item.Order_No,
            item.Cust_Po,
            item.RMA,
            item.Item,
            item.OEM_Part,
            item.Description,
            item.Model,
            item.Class,
            item.OEM,
            item.Std_Cost,
            item.Qty,
            item.Warranty,
            item.BCN,
            item.Serial_No,
            item.ESN,
            item.License_Plate,
            item.Date_Rcvd,
            item.Order_Type,
            item.RMA_Type,
            item.Reason_Code,
            item.Condition,
            item.DNC,
            item.CRA,
            item.Carrier,
            item.Waybill_inbound,
            item.Packslip_inbound,
            item.Vendor_Ref_No,
            item.Location,
            item.Warehouse,
            item.Observed_Problem,
            item.Comments,
          ]);
          row.eachCell({ includeEmpty: true }, (cell) => {
            //Apply borders
            cell.border = borderStyle;
          });
        });
        //Download the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), Utility.excelFileName("Sales_Order_Receipts"));
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Wnd the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
